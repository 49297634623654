import { Injectable, Inject,  PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponseInterface } from './response.interface';

@Injectable()
export class RequestService {

  get admin(): any {
    const account = localStorage.getItem('account');
    return account ? JSON.parse(account) : null;
  }

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object) {
    }

  /* usage: RequestService.send('get', 'https://api/path', { foo: 'bar' }, true);
   */
  public send(method: string, url: string, params: any = {}): Observable<{}|IResponseInterface> {
    method  = method.toLowerCase();

    let request: Observable<{}|IResponseInterface>;
    const contentType = params instanceof FormData ? null : 'application/json';
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(contentType));

    switch (method) {
      case 'get':
        request = this.httpClient.get(url, { params: this.buildParams(params), headers });
        break;

      case 'post':
        request = this.httpClient.post(url, params, { headers });
        break;

      case 'patch':
        request = this.httpClient.patch(url, params, { headers });
        break;

      default:
        request = this.httpClient.request(method, url, { headers, ...params });
        break;
    }

    return request;
  }

  /*
   * Version of `send()` above using HttpClient
   * https://angular.io/guide/http#reading-the-full-response
  */
  public request(method: string, url: string, options: any = {}): Observable<IResponseInterface| {}> {

    method  = method.toLowerCase();

    let request: Observable<IResponseInterface|Object>;
    const contentType = options instanceof FormData ? null : 'application/json';
    const headers: HttpHeaders = new HttpHeaders(this.buildHeaders(contentType));
    const observe = options.observe || 'response';

    switch (method) {
      case 'post':
        request = this.httpClient.post(url, options, { headers, observe: 'response' });
        break;

      case 'patch':
        request = this.httpClient.patch(url, options, { headers, observe: 'response' });
        break;

      default:
        request = this.httpClient.request(method, url, { ...options, headers, observe });
        break;
    }

    return request;
  }

  /*  Send requests as promise
   *
   */
  public promise(method: string, url: string, options?: any): Promise<any> {
    return this.send(method, url, options).toPromise()
      .then(response =>
        response)
      .catch(this.handleError);
  }

  /*  Helper to build params usage:
   *
   *   buildParms({ foo: 'bar', pizza: 'pepperoni' }).toString() // "foo=bar&pizza=pepperoni"
   */
  public buildParams(options: any): HttpParams {
    Object.keys(options || {}).forEach(k => {
      if (options[k] == null || options[k] === undefined) {
        delete options[k];
      }
    });

    return options;
  }

  public buildHeaders(contentType?: any): any {

    const headers: any = {
      'x-sqz-client': 'squeeze-web',
    };

    if (contentType) {
      headers['content-type'] = contentType;
    }

    if ( isPlatformBrowser(this.platformId) ) {
      if (this.admin) {
        if (this.admin.jwt) { headers['x-sqz-token'] = this.admin.jwt; }
        if (this.admin.account) { headers['x-sqz-userid'] = this.admin.account.id; }
      }
    }

    return headers;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.error || error.message || error.Error || error.msg || error);
  }

}
